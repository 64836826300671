
import '../App.css';
import Register from '../components/GroupPages/RegisterPage/Register';
const RegisterPage = () => {
    return (
        <>
            <Register/>
        </>
    );
}

export default RegisterPage;