import React from 'react';
import UserGroups from '../components/GroupPages/GroupViewAllPage/UserGroups';

const UserGroupPage = () => {
        return (
                <>
                        <UserGroups/>
                </>
                
        );
}

export default UserGroupPage;