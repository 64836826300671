import '../App.css';
import GroupForm from '../components/GroupPages/GroupCreationPage/groupForm';
const GroupCreationPage = () => {
    return (
        <>
            <GroupForm/>
        </>
    );
}

export default GroupCreationPage;